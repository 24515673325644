import React from "react";

function FooterDivider() {
  return (
    <>
    <div className="hidden md:block" style={{backgroundColor:`black`, width:`1px`, height:`24px`,}}></div>
    </>
  );
}

export default FooterDivider;